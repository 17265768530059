import { ReactComponent as BackIcon } from "../../icons/back-icon.svg";

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchReviews } from '../../components/Reviews/actions';
import { getReviewsFetching, getReviews } from '../../components/Reviews/reducer';
import Rating from '../../components/Rating';
import './style.css';
import {Radio} from "semantic-ui-react";

const ReviewCard = ({ productId }) => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => getReviewsFetching(state.reviews));
    const reviews = useSelector((state) => getReviews(state.reviews, productId));

    // useEffect(() => {
    //     if (productId) {
    //         dispatch(fetchReviews(productId));
    //     }
    // }, [dispatch, productId]);

    if (loading === 1) {
        return <div>Загрузка отзывов...</div>;
    }

    if (!reviews || reviews.length === 0) {
        return <div>Отзывов пока нет</div>;
    }

    return (
        <div className="review-card">
            <div className="review-card-header">Отзывы</div>
            <div className="review-card-content">
                {reviews.map((review) => (
                    <div key={review.id} className="review-row-container">
                        <div className="review-header">
                            <span className="review-name">{review.reviewer}</span>
                            <Rating rating={review.rating} />
                        </div>
                        <div className="review-text">{review.review}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

ReviewCard.propTypes = {
    productId: PropTypes.number.isRequired,
};


function ReviewsList({ productId }) {
    return (
        <div>
            <ReviewCard productId={productId} />
        </div>
    );
}

ReviewsList.propTypes = {
    productId: PropTypes.number.isRequired,
};


export default function MyReviews({ navigate, productId }) {
    const [selectedReviews, setSelectedReviews] = useState(null);

    function handleChange(e, { value }) {
        setSelectedReviews(value);
    }

    return (
        <>
            <div className="service-payment-container">
                <div className="service-payment-header">
                    <BackIcon className="service-payment-header-img" onClick={() => navigate('/profile')} />
                    <div className="title-text">Мои отзывы</div>
                </div>
                <div className="checkboxes-box">
                    <Radio
                        label="От клиента"
                        name="radioGroup"
                        value="client"
                        checked={selectedReviews === 'client'}
                        onChange={handleChange}
                        className="checkbox-box"
                    />
                    <Radio
                        label="От старшего специалиста"
                        name="radioGroup"
                        value="specialist"
                        checked={selectedReviews === 'specialist'}
                        onChange={handleChange}
                        className="checkbox-box"
                    />
                </div>
                <ReviewsList productId={productId} />
            </div>
        </>
    );
}