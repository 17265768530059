import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {isMobile} from "react-device-detect";

const PaginatedTasks = ({ tasks, tasksPerPage }) => {
    const [currentPage, setCurrentPage] = useState(1);

    const navigate = useNavigate();
    const totalPages = Math.ceil(tasks.length / tasksPerPage);

    const startIndex = (currentPage - 1) * tasksPerPage;
    const currentTasks = tasks.slice(startIndex, startIndex + tasksPerPage);

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prev) => prev + 1);
        }
    };

    const goToPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prev) => prev - 1);
        }
    };

    const goToPage = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className="paginated-tasks">
            <span className="title-text">Незапланированные задачи</span>
            {currentTasks.length > 0 ? (
                <div className="profile-tasks-container">
                    {currentTasks.map((item) => (
                        <div
                            className="profile-task"
                            key={`${item.id_order}-${item.id}`}
                            onClick={() => navigate(`/task/${item.id}`)}
                        >
                            <div
                                className="profile-task-number"
                                style={{
                                    borderRadius: "10px 0 0 0",
                                    backgroundColor: item.id_order % 2 === 0 ? "#F6FCFF" : "#EBF6FF",
                                    width: isMobile ? "75px" : "",
                                }}
                            >
                                №{item.id_order}
                            </div>
                            <div className="employment-profile-task-text">{item.product_name}</div>
                            <div className="profile-task-company">{item.customer_address.company}</div>
                        </div>
                    ))}
                </div>
            ) : (
                <div style={{ marginLeft: "5px" }}>Нет задач</div>
            )}

            {totalPages > 1 && (
                <div
                    className="pagination-controls"
                    style={{
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "center", // Центрируем элементы
                        alignItems: "center",
                        gap: "10px",
                    }}
                >
                    <button
                        onClick={goToPreviousPage}
                        disabled={currentPage === 1}
                        className="pagination-button"
                        style={{ cursor: currentPage === 1 ? "not-allowed" : "pointer" }}
                    >
                        &#8592;
                    </button>

                    <div className="pagination-numbers" style={{ display: "flex", gap: "5px" }}>
                        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                            <button
                                key={page}
                                onClick={() => goToPage(page)}
                                className={`pagination-number ${currentPage === page ? "active" : ""}`}
                                style={{
                                    padding: "5px 10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    backgroundColor: currentPage === page ? "#007bff" : "#fff",
                                    color: currentPage === page ? "#fff" : "#000",
                                    cursor: "pointer",
                                }}
                            >
                                {page}
                            </button>
                        ))}
                    </div>

                    <button
                        onClick={goToNextPage}
                        disabled={currentPage === totalPages}
                        className="pagination-button"
                        style={{ cursor: currentPage === totalPages ? "not-allowed" : "pointer" }}
                    >
                        &#8594;
                    </button>
                </div>
            )}
        </div>
    );
};

export default PaginatedTasks;