import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const DoubleLineChart = (props) => {
    const {totalTasks} = props;
    const currentYear = new Date().getFullYear();
    const filteredData = Object.keys(totalTasks)
        .filter(key => key.startsWith(`${currentYear}-`))
        .reduce((acc, key) => {
            acc[key] = totalTasks[key];
            return acc;
        }, {});
    for(let i = 0; i < 12; i++){
        if(i < 9) {
            filteredData[`${currentYear}-0${i+1}`] = filteredData[`${currentYear}-0${i+1}`] ? filteredData[`${currentYear}-0${i+1}`] : {earned_sum: 0, total_in_work: 0, total_closed: 0}
        } else {
            filteredData[`${currentYear}-${i+1}`] = filteredData[`${currentYear}-${i+1}`] ? filteredData[`${currentYear}-${i+1}`] : {earned_sum: 0, total_in_work: 0, total_closed: 0}
        }
    }
    const dataGraph = Object.entries(filteredData)
        .sort(([keyA], [keyB]) => {
            const dateA = new Date(keyA + '-01');
            const dateB = new Date(keyB + '-01');
            return dateA - dateB;
        })
        .map(([key, value]) => value.total_closed);
    console.log(dataGraph, 's')

    // Данные для графика
    const data = {
        labels: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
        datasets: [
            {
                label: 'Всего задач',
                data: dataGraph,
                borderColor: '#0075F5',
                backgroundColor: '#6DCBFF',
                fill: true,
                pointRadius: 3,
            },
        ],
    };

    // Опции графика
    const options = {
        responsive: true,
        scales: {
            x: {
                title: {
                    display: false,
                    text: 'Месяц',
                },
            },
            y: {
                title: {
                    display: false,
                    text: 'Значения',
                },
                min: 0,
                max: 20,
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                position: 'top',
                display: true,
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
        },
    };

    return (
        <Line data={data} options={options} />
    );
};

export default DoubleLineChart;
