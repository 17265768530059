import React, { useEffect, useState } from "react";
import { Radio } from "semantic-ui-react";
import { ReactComponent as BackIcon } from "../../icons/back-icon.svg";
import "./style.css";
import CalendarTable from "../../components/CalendarTable";
import { useSelector } from "react-redux";
import { getTasks } from "../Tasks/reducer";
import { fetchTasks } from "../Tasks/actions";
import PaginatedTasks from "../../components/PaginationTasks";

const EmploymentCalendar = (props) => {
    const { navigate, dispatch } = props;
    const token = useSelector((state) => state.userLogin.userToken.token);
    const allTasks = useSelector((state) => getTasks(state.tasks));
    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        if (token) {
            dispatch(fetchTasks(token));
        }
    }, [token, dispatch]);

    useEffect(() => {
        const filteredTasks = Object.values(allTasks).filter(
            (item) => item.task_status === "new"
        );
        setTasks(filteredTasks);
        console.log(filteredTasks)
    }, [allTasks]);

    const [selectedPlanned, setSelectedPlanned] = useState(null);

    const handleChange = (e, { value }) => {
        setSelectedPlanned(value);
    };

    return (
        <>
            <div className="service-payment-container">
                <div className="service-payment-header">
                    <BackIcon
                        className="service-payment-header-img"
                        onClick={() => navigate("/profile")}
                    />
                    <div className="title-text">Календарь занятости</div>
                </div>

                <div className="checkboxes-box" style={{ marginBottom: "10px" }}>
                    <Radio
                        label="Запланированные"
                        name="radioGroup"
                        value="planned"
                        checked={selectedPlanned === "planned"}
                        onChange={handleChange}
                        className={"checkbox-box"}
                    />
                    <Radio
                        label="Завершенные"
                        name="radioGroup"
                        value="done"
                        checked={selectedPlanned === "done"}
                        onChange={handleChange}
                        className={"checkbox-box"}
                    />
                </div>
            </div>
            <CalendarTable />
            <div className="profile-tasks" style={{ marginLeft: "2.5%"}}>
                <PaginatedTasks tasks={tasks} tasksPerPage={10} />
            </div>
        </>
    );
};

export default EmploymentCalendar;